import { defineStore } from 'pinia'
import ParentRepository from '~/repositories/Creche/ParentRepository.js'

export const useParentsStore = defineStore('parents', {
    state: () => ({
        parents: []
    }),

    actions: {
        fetchParents (crecheUuid = null) {
            return ParentRepository.fetchAll(crecheUuid)
                .then((response) => {
                    this.parents = response.data
                })
        }
    }
})
