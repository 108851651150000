import { mapActions, mapState } from 'pinia'
import { useParentsStore } from '~/stores/parents.js'

export default {
    computed: {
        ...mapState(useParentsStore, ['parents']),
        parentsOptions () {
            return this.parents.map((parent) => {
                return {
                    value: parent.uuid,
                    label: parent.first_name + ' ' + parent.last_name
                }
            })
        }
    },

    created () {
        this.fetchParents()
    },

    methods: {
        ...mapActions(useParentsStore, ['fetchParents'])
    }
}
